import { render, staticRenderFns } from "./TeamDataReview.vue?vue&type=template&id=0120cb45&scoped=true"
import script from "./TeamDataReview.vue?vue&type=script&lang=js"
export * from "./TeamDataReview.vue?vue&type=script&lang=js"
import style0 from "./TeamDataReview.vue?vue&type=style&index=0&id=0120cb45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0120cb45",
  null
  
)

export default component.exports